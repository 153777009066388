import request from '@/utils/request'

const permission_groupApi = {
  permission_group_list: '/permission_group/',
  permission_group_create: '/permission_group/',
  permission_group_update: '/permission_group/',
  permission_group_delete: '/permission_group/'
}

/**
 * 列表
 */
export function permission_group_list (parameter) {
  return request({
    url: permission_groupApi.permission_group_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function permission_group_create (parameter) {
  return request({
    url: permission_groupApi.permission_group_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function permission_group_update (parameter, permission_group_id) {
  return request({
    url: permission_groupApi.permission_group_update + permission_group_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function permission_group_delete (permission_group_id) {
  return request({
    url: permission_groupApi.permission_group_delete + permission_group_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
